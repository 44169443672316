<i (click)="openMenu($event)" class="fas fa-bars trigger-menu"></i>
<dougs-sidebar-menu
  (clickOutside)="onClickOutsideMenu()"
  [isCompact]="isCompact"
  [ngClass]="{ 'is-open': isMenuOpened }"
  class="sidebar-menu"
  dougsClickOutside
  [logoName]="sidebarComponentService.logo$ | async"
>
  <dougs-sidebar-menu-item
    *ngIf="
      (sidebarModuleAccessComponentService.shouldShowPerformanceLink$ | async) ||
      (sidebarModuleAccessComponentService.wouldSeeBlankSlate$ | async)
    "
    [dougsTooltip]="isCompact ? 'Performance' : ''"
    [isActive]="isRouterActiveAndNotificationClosed(rlaPerformance)"
    [isCompact]="isCompact"
    dougsTooltipPosition="right"
    (click)="sidebarComponentService.sendClickMenuEvent('Performance')"
  >
    <a #rlaPerformance="routerLinkActive" [routerLink]="URL.PERFORMANCE | createUrl | async" routerLinkActive>
      <i class="sidebar-icon fal fa-chart-mixed"></i>
      <span class="menu-title">Performance</span>
    </a>
  </dougs-sidebar-menu-item>
  <dougs-sidebar-menu-item
    *ngIf="sidebarModuleAccessComponentService.shouldShowOldOnboardingCreationLink$ | async"
    [dougsTooltip]="isCompact ? 'Création d\'entreprise' : ''"
    [isActive]="isRouterActiveAndNotificationClosed(rlaEnterprise)"
    [isCompact]="isCompact"
    dougsTooltipPosition="right"
    (click)="sidebarComponentService.sendClickMenuEvent('Creation')"
  >
    <a
      #rlaEnterprise="routerLinkActive"
      [routerLink]="sidebarComponentService.relevantOnboardingCreationUrl$ | async | createUrl | async"
      routerLinkActive
    >
      <i class="sidebar-icon fal fa-building"></i>
      <span class="menu-title">Création d'entreprise</span>
    </a>
  </dougs-sidebar-menu-item>
  <dougs-sidebar-menu-item
    *ngIf="sidebarModuleAccessComponentService.shouldShowNewOnboardingCreationLink$ | async"
    [dougsTooltip]="isCompact ? 'Création d\'entreprise' : ''"
    [isActive]="isRouterActiveAndNotificationClosed(rlaEnterprise)"
    [isCompact]="isCompact"
    dougsTooltipPosition="right"
    (click)="sidebarComponentService.sendClickMenuEvent('Creation')"
  >
    <a #rlaEnterprise="routerLinkActive" [routerLink]="URL.ONBOARDING_CREATION | createUrl | async" routerLinkActive>
      <i class="sidebar-icon fal fa-building"></i>
      <span class="menu-title">Création d'entreprise</span>
    </a>
  </dougs-sidebar-menu-item>
  <dougs-sidebar-menu-item
    *ngIf="sidebarModuleAccessComponentService.shouldShowAccountingLink$ | async"
    [dougsTooltip]="isCompact ? 'Comptabilité' : ''"
    [isActive]="isRouterActiveAndNotificationClosed(rlaAccounting)"
    [isCompact]="isCompact"
    dougsTooltipPosition="right"
    (click)="sidebarComponentService.sendClickMenuEvent('Accounting')"
  >
    <a #rlaAccounting="routerLinkActive" [routerLink]="URL.ACCOUNTING | createUrl | async" routerLinkActive>
      <i class="sidebar-icon fal fa-money-bills-simple"></i>
      <span class="menu-title">Comptabilité</span>
      <ng-container
        *ngIf="{
          operationsCount: notValidatedOperationsStateService.operationsCount$ | async,
          hasSynchronizingAccount: (synchronizedAccountStateService.synchronizingAccounts$ | async)?.length
        } as accountingInfo"
      >
        <span
          *ngIf="
            accountingInfo.operationsCount &&
            !(sidebarModuleAccessComponentService.shouldShowAccountingLinkForBlankSlate$ | async) &&
            !accountingInfo.hasSynchronizingAccount
          "
          class="pill"
        >
          {{ accountingInfo.operationsCount > 99 ? '99+' : accountingInfo.operationsCount }}
        </span>
        <div *ngIf="accountingInfo.hasSynchronizingAccount" class="spinner">
          <i class="fad fa-spinner-third fa-spin fa-lg"></i>
        </div>
      </ng-container>
      <span *ngIf="sidebarModuleAccessComponentService.shouldShowAccountingLinkForBlankSlate$ | async" class="pill new">
        NEW
      </span>
    </a>
  </dougs-sidebar-menu-item>
  <dougs-sidebar-menu-item
    *ngIf="sidebarModuleAccessComponentService.shouldShowInvoicingLink$ | async"
    [dougsTooltip]="isCompact ? 'Factures de vente' : ''"
    [isActive]="isRouterActiveAndNotificationClosed(rlaInvoicing)"
    [isCompact]="isCompact"
    dougsTooltipPosition="right"
    (click)="sidebarComponentService.sendClickMenuEvent('Invoicing')"
  >
    <a #rlaInvoicing="routerLinkActive" [routerLink]="URL.SALES_INVOICE | createUrl | async" routerLinkActive>
      <i class="sidebar-icon fal fa-file-invoice"></i>
      <span class="menu-title">Factures de vente</span>
    </a>
  </dougs-sidebar-menu-item>
  <dougs-sidebar-menu-item
    *ngIf="sidebarModuleAccessComponentService.shouldShowVendorInvoiceLink$ | async"
    [dougsTooltip]="isCompact ? 'Factures d\'achat' : ''"
    [isActive]="isRouterActiveAndNotificationClosed(rlaServices)"
    [isCompact]="isCompact"
    dougsTooltipPosition="right"
    (click)="sidebarComponentService.sendClickMenuEvent('Supplier Invoicing')"
  >
    <a #rlaServices="routerLinkActive" [routerLink]="URL.VENDOR_INVOICE | createUrl | async" routerLinkActive>
      <i class="sidebar-icon fal fa-receipt"></i>
      <span class="menu-title">Factures d'achat</span>
    </a>
  </dougs-sidebar-menu-item>
  <dougs-sidebar-menu-item
    *ngIf="sidebarModuleAccessComponentService.shouldShowDeclarationLink$ | async"
    [dougsTooltip]="isCompact ? 'Déclaration' : ''"
    [isActive]="isRouterActiveAndNotificationClosed(rlaDeclaration)"
    [isCompact]="isCompact"
    dougsTooltipPosition="right"
    (click)="sidebarComponentService.sendClickMenuEvent('Declarations')"
  >
    <a #rlaDeclaration="routerLinkActive" [routerLink]="URL.DECLARATION | createUrl | async" routerLinkActive>
      <i class="sidebar-icon fal fa-building-columns"></i>
      <span class="menu-title">Déclarations</span>
    </a>
  </dougs-sidebar-menu-item>
  <dougs-sidebar-menu-item
    *ngIf="sidebarModuleAccessComponentService.shouldShowInvestmentLink$ | async"
    [dougsTooltip]="isCompact ? 'Immobilisations' : ''"
    [isActive]="isRouterActiveAndNotificationClosed(rlaInvestment)"
    [isCompact]="isCompact"
    dougsTooltipPosition="right"
    (click)="sidebarComponentService.sendClickMenuEvent('Investments')"
  >
    <a #rlaInvestment="routerLinkActive" [routerLink]="URL.INVESTMENT | createUrl | async" routerLinkActive>
      <i class="sidebar-icon fal fa-house-circle-check"></i>
      <span class="menu-title">Immobilisations</span>
    </a>
  </dougs-sidebar-menu-item>
  <dougs-sidebar-menu-item
    *ngIf="sidebarModuleAccessComponentService.shouldShowAccountingSurveyLink$ | async"
    [dougsTooltip]="isCompact ? 'Questions bilan' : ''"
    [isCompact]="isCompact"
    [isActive]="isRouterActiveAndNotificationClosed(rlaAccountingSurvey)"
    dougsTooltipPosition="right"
  >
    <a
      #rlaAccountingSurvey="routerLinkActive"
      [routerLink]="URL.ACCOUNTING_SURVEY | createUrl | async"
      routerLinkActive
    >
      <i class="sidebar-icon fal fa-ballot-check"></i>
      <span class="menu-title">Questions Bilan</span>
    </a>
    <span *ngIf="sidebarComponentService.accountingSurveyCompletion$ | async as percent" class="pill">
      {{ percent }}%
    </span>
  </dougs-sidebar-menu-item>
  <dougs-sidebar-menu-item
    *ngIf="sidebarComponentService.shouldShowServicesLink$ | async"
    [dougsTooltip]="isCompact ? 'Prestations' : ''"
    [isActive]="isRouterActiveAndNotificationClosed(rlaServices)"
    [isCompact]="isCompact"
    dougsTooltipPosition="right"
    (click)="sidebarComponentService.sendClickMenuEvent('Prestations')"
  >
    <a #rlaServices="routerLinkActive" [routerLink]="URL.SERVICE | createUrl | async" routerLinkActive>
      <i class="sidebar-icon fal fa-briefcase"></i>
      <span class="menu-title">Prestations</span>
      <ng-container *ngIf="sidebarComponentService.countServicesUncompleted$ | async as countServicesUncompleted">
        <span *ngIf="countServicesUncompleted > 0" class="pill">{{ countServicesUncompleted }}</span>
      </ng-container>
      <ng-container *ngIf="sidebarComponentService.shouldShowNewBadgeOnServices$ | async">
        <span class="pill yellow">NEW</span>
      </ng-container>
    </a>
  </dougs-sidebar-menu-item>
  <dougs-sidebar-menu-item [isCompact]="isCompact" dougsSidebarMenuSeparator />
  <dougs-sidebar-menu-item
    *ngIf="sidebarModuleAccessComponentService.shouldShowSettingsLink$ | async"
    [dougsTooltip]="isCompact ? 'Paramètres' : ''"
    [isActive]="isRouterActiveAndNotificationClosed(rlaSettings)"
    [isCompact]="isCompact"
    dougsTooltipPosition="right"
    (click)="sidebarComponentService.sendClickMenuEvent('Parameters')"
  >
    <a #rlaSettings="routerLinkActive" [routerLink]="URL.SETTINGS | createUrl | async" routerLinkActive>
      <i class="sidebar-icon fal fa-gear"></i>
      <span class="menu-title">Paramètres</span>
      <span
        *ngIf="
          (companyStateService.activeCompany$ | async)?.completion?.percent &&
          (companyStateService.activeCompany$ | async)?.completion?.percent < 100
        "
        class="pill"
      >
        {{ (companyStateService.activeCompany$ | async)?.completion?.percent }}%
      </span>
    </a>
  </dougs-sidebar-menu-item>
  <dougs-sidebar-menu-item
    *ngIf="sidebarModuleAccessComponentService.shouldShowNotificationLink$ | async"
    [dougsTooltip]="isCompact ? 'À faire' : ''"
    [isCompact]="isCompact"
    [isActive]="rlaNotifications.isActive"
    dougsTooltipPosition="right"
    (click)="sidebarComponentService.sendClickMenuEvent('Customer Todo')"
  >
    <a
      (click)="$event.preventDefault()"
      #rlaNotifications="routerLinkActive"
      [routerLink]="URL.TODO | createUrl | async"
      routerLinkActive
    >
      <i class="sidebar-icon fal fa-check-circle"></i>
      <span class="menu-title">À faire</span>
      <dougs-badge *ngIf="(userTasksStateService.tasks$ | async)?.length > 0" [ngClass]="{ 'ml-8': !isCompact }">
        {{ (userTasksStateService.tasks$ | async).length }}
      </dougs-badge>
    </a>
  </dougs-sidebar-menu-item>
  <dougs-sidebar-menu-item
    *ngIf="sidebarModuleAccessComponentService.shouldShowHelpCenterLink$ | async"
    [dougsTooltip]="isCompact ? 'Centre d’aide' : ''"
    [isCompact]="isCompact"
    dougsTooltipPosition="right"
    (click)="sidebarComponentService.sendClickMenuEvent('Help Center')"
  >
    <a href="https://aide.dougs.fr/fr/" target="_blank" rel="noopener">
      <i class="sidebar-icon fal fa-life-ring"></i>
      <span class="menu-title">Centre d’aide</span>
      <i
        *ngIf="!(userStateService.activeUser$ | async)?.metadata?.compactAppAside"
        class="fal fa-external-link mr-8"
      ></i>
    </a>
  </dougs-sidebar-menu-item>
  <dougs-sidebar-menu-item
    *ngIf="sidebarModuleAccessComponentService.shouldShowCreationLink$ | async"
    [dougsTooltip]="isCompact ? 'Création' : ''"
    [isActive]="isRouterActiveAndNotificationClosed(rlaCreation)"
    [isCompact]="isCompact"
    dougsTooltipPosition="right"
    [isAdmin]="true"
  >
    <a
      #rlaCreation="routerLinkActive"
      [class.disabled]="rlaCreation.isActive"
      [routerLink]="URL.CREATION | createUrl | async"
      routerLinkActive
    >
      <i class="sidebar-icon fal fa-building-circle-check"></i>
      <span class="menu-title">Création</span>
    </a>
  </dougs-sidebar-menu-item>
  <dougs-sidebar-menu-item
    *ngIf="sidebarModuleAccessComponentService.shouldShowAccountingReviewLink$ | async"
    [dougsTooltip]="isCompact ? 'Révision' : ''"
    [isActive]="isRouterActiveAndNotificationClosed(rlaAccountingReview)"
    [isCompact]="isCompact"
    dougsTooltipPosition="right"
    [isAdmin]="true"
  >
    <a
      #rlaAccountingReview="routerLinkActive"
      [class.disabled]="rlaAccountingReview.isActive"
      [routerLink]="URL.ACCOUNTING_REVIEW | createUrl | async"
      routerLinkActive
    >
      <i class="sidebar-icon fal fa-calculator-simple"></i>
      <span class="menu-title">Révision</span>
    </a>
  </dougs-sidebar-menu-item>
  <dougs-sidebar-menu-item
    *ngIf="sidebarModuleAccessComponentService.shouldShowUsersListingLink$ | async"
    [dougsTooltip]="isCompact ? 'Utilisateurs' : ''"
    [isCompact]="isCompact"
    dougsTooltipPosition="right"
    [isAdmin]="true"
  >
    <a (click)="sidebarComponentService.openUserModal()">
      <i class="sidebar-icon fal fa-user-magnifying-glass"></i>
      <span class="menu-title">Utilisateurs</span>
    </a>
  </dougs-sidebar-menu-item>
  <dougs-sidebar-menu-item
    *ngIf="sidebarModuleAccessComponentService.shouldShowTaskLink$ | async"
    [dougsTooltip]="isCompact ? 'Tâches' : ''"
    [isActive]="isRouterActiveAndNotificationClosed(rlaTasks)"
    [isCompact]="isCompact"
    dougsTooltipPosition="right"
    [isAdmin]="true"
  >
    <a
      #rlaTasks="routerLinkActive"
      [class.disabled]="rlaTasks.isActive"
      [routerLink]="URL.TASKS | createUrl | async"
      routerLinkActive
    >
      <i class="sidebar-icon fal fa-clipboard-check"></i>
      <span class="menu-title">Tâches</span>
    </a>
  </dougs-sidebar-menu-item>
  <dougs-sidebar-menu-item
    *ngIf="sidebarModuleAccessComponentService.shouldShowAccountantOrAdminLink$ | async"
    [dougsTooltip]="isCompact ? 'Cockpit' : ''"
    [isActive]="isRouterActiveAndNotificationClosed(rlaCockpit)"
    [isCompact]="isCompact"
    dougsTooltipPosition="right"
    [isAdmin]="true"
  >
    <a
      #rlaCockpit="routerLinkActive"
      [class.disabled]="rlaCockpit.isActive"
      [routerLink]="URL.COCKPIT | createUrl | async"
      routerLinkActive
    >
      <i class="sidebar-icon fal fa-rocket-launch"></i>
      <span class="menu-title">Cockpit</span>
      <dougs-badge
        type="primary"
        *ngIf="(cockpitStateService.totalUnseen$ | async) > 0"
        [ngClass]="{ 'ml-8': !isCompact }"
      >
        {{ cockpitStateService.totalUnseen$ | async }}
      </dougs-badge>
    </a>
  </dougs-sidebar-menu-item>
  <dougs-sidebar-menu-item
    *ngIf="sidebarModuleAccessComponentService.shouldShowSupportLink$ | async"
    [dougsTooltip]="isCompact ? 'Support' : ''"
    [isCompact]="isCompact"
    dougsTooltipPosition="right"
    [isAdmin]="true"
  >
    <a (click)="sidebarComponentService.openSupportModal()">
      <i class="sidebar-icon fal fa-headset"></i>
      <span class="menu-title">Support</span>
    </a>
  </dougs-sidebar-menu-item>
  <dougs-sidebar-menu-item
    *ngIf="sidebarComponentService.shouldShowCompanyLink$ | async"
    [dougsTooltip]="isCompact ? 'Dossiers' : ''"
    [isActive]="isRouterActiveAndNotificationClosed(rlaCompany)"
    [isCompact]="isCompact"
    [isAdmin]="true"
    dougsTooltipPosition="right"
  >
    <a #rlaCompany="routerLinkActive" [routerLink]="URL.COMPANIES | createUrl | async" routerLinkActive>
      <i class="fal fa-folders"></i>
      <span class="menu-title">Dossiers</span>
    </a>
  </dougs-sidebar-menu-item>
  <dougs-user-menu
    [isCompact]="isCompact"
    (click)="sidebarComponentService.sendClickMenuEvent('User Profile')"
  ></dougs-user-menu>
  <div
    *ngIf="sidebarModuleAccessComponentService.shouldShowChecklistLink$ | async"
    [ngClass]="{ 'mx-8 p-4': isCompact, 'mx-16 p-8': !isCompact }"
    (click)="toggleChecklist($event)"
    class="checklist-toggle-button pointer mt-16"
    dougs-sidebar-menu-bottom
  >
    <div class="checklist-toggle-button__img-container">
      <img src="images/onboarding/patrick.png" alt="Patrick Dougs" />
    </div>
    <p class="color-green-700">Bien démarrer<br />votre entreprise</p>
    <i class="fal fa-chevron-right color-primary-700"></i>
  </div>
</dougs-sidebar-menu>
<dougs-checklist
  [isOpen]="isChecklistOpened"
  [isCompact]="isCompact"
  (close)="closeChecklist()"
  (clickOutside)="closeChecklist()"
  dougsClickOutside
></dougs-checklist>
<div *ngIf="sidebarComponentService.canSeeBanner$ | async" class="banner">
  <div class="notifications">
    <ng-container
      *ngIf="{ accountingOnboarding: accountingOnboardingStateService.accountingOnboarding$ | async } as onboarding"
    >
      <dougs-company-creation-xsell
        *ngIf="
          (companyServicesStateService.services$ | async)?.creation?.eligibility === 'eligible' &&
          !onboarding.accountingOnboarding?.data?.shouldShowCompanyCreationService &&
          (userStateService.activeUser$ | async)?.role === 'customer'
        "
      ></dougs-company-creation-xsell>
    </ng-container>

    <dougs-trial
      *ngIf="sidebarComponentService.showTrial$ | async"
      joyrideStep="trial"
      stepPosition="right"
      productTour
      [productTourContent]="(accountingProductsTourService.steps$ | async)?.['trial']"
    ></dougs-trial>
    <dougs-accounting-survey-reminder
      *ngIf="
        !(sidebarComponentService.showTrial$ | async) &&
        (sidebarComponentService.shouldShowAccountingSurveyReminder$ | async)
      "
    ></dougs-accounting-survey-reminder>
  </div>
</div>
<ng-content></ng-content>
